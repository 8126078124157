<template>
    <div class="main-wrapper">

        <!-- navigation -->
        <nav class="navigation scroll-bar" v-bind:class="{'nav-active': isNavbarActive, 'menu-active': isMenuActive}">
            <div class="container pl-0 pr-0">
                <div class="nav-content">
                    <div class="nav-top">
                        <router-link to="/admin/courses"><div class="display1-size mr-2 ml-4 mb-1"><img :src="'/images/logo-1-new.png'" width="40"></div><span class="d-inline-block fredoka-font ls-3 fw-600 text-current-eling font-xl logo-text mb-0">Elingway </span> </router-link>
                        <a href="#" class="close-nav d-inline-block d-lg-none" @click="toggleNavbar()"><i class="ti-close bg-grey mb-4 btn-round-sm font-xssss fw-700 text-dark ml-auto mr-2 "></i></a>
                    </div>
                    <div class="mb-3">
                        <div class="nav-caption fw-600 font-xssss text-grey-500">
                            <span>Course </span>Menu
                        </div>
                        <ul class="mb-3">
                            <li class="logo d-none d-xl-block d-lg-block"></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/home`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/home`)}"  class="nav-content-bttn open-font"><i class="feather-home mr-3"></i><span>Home</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/announcements`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/announcements`)}"  class=" nav-content-bttn open-font"><i class="feather-volume-2 mr-3"></i><span>Announcements</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/people`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/people`)}"  class="nav-content-bttn open-font"><i class="feather-users mr-3"></i><span>People</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/meet-tutors`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/meet-tutors`)}"  class="nav-content-bttn open-font"><i class="feather-message-circle mr-3"></i><span>Meet Tutors</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/attendance`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/attendance`)}"  class="nav-content-bttn open-font"><i class="feather-check-square mr-3"></i><span>Attendance</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/syllabus`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/syllabus`)}"  class="nav-content-bttn open-font"><i class="feather-book-open mr-3"></i><span>Syllabus</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/rubrics`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/rubrics`)}"  class="nav-content-bttn open-font"><i class="feather-box mr-3"></i><span>Rubrics</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/assignments`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/assignments`)}"  class="nav-content-bttn open-font"><i class="feather-bookmark mr-3"></i><span>Assessment Tasks</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/files`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/files`)}"  class="nav-content-bttn open-font"><i class="feather-archive mr-3"></i><span>Materials</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/conferences`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/conferences`)}"  class="nav-content-bttn open-font"><i class="feather-cast mr-3"></i><span>E-Talks</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/progress-grading`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/progress-grading`)}"  class="nav-content-bttn open-font"><i class="feather-bar-chart mr-3"></i><span>Progress & Grading</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/goal-analytics`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/goal-analytics`)}"  class="nav-content-bttn open-font"><i class="feather-search mr-3"></i><span>Goal Analytics</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/evaluation`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/evaluation`)}"  class="nav-content-bttn open-font"><i class="feather-alert-circle mr-3"></i><span>Evaluation</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/reflection`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/reflection`)}"  class="nav-content-bttn open-font"><i class="feather-repeat mr-3"></i><span>Reflection</span></router-link></li>
                            <li><router-link :to="`/admin/courses/${paramsId}/coupon`" :class="{'active' : isActiveMenu(`/admin/courses/${paramsId}/coupon`)}"  class="nav-content-bttn open-font"><i class="feather-gift mr-3"></i><span>Coupon</span></router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
        <!-- navigation -->
        <!-- main content -->
        <div class="main-content min-vh-100" v-bind:class="{'menu-active': isMenuActive}">
            <div class="middle-sidebar-header bg-white position-sticky">
                <button class="header-menu" @click="toggleNavbar()"></button>
                <div action="#" class="float-left w-100 header-search">
                    <div class="form-group mb-0 icon-input">
                        <i class="feather-menu font-lg text-grey-400 mt-0" @click="toggleMenu()"></i>
                        <input type="text" disabled :value="course.name" class="bg-transparent border-0 lh-32 ml-3 pt-2 pb-2 pl-5 pr-3 font-xs fw-600 rounded-xl w-100">
                    </div>
                </div>
                <ul class="d-flex ml-auto right-menu-icon">
                    <li>
                        <notification/>
                    </li>
                    <li><router-link to="/message"><span class="dot-count bg-warning"></span><i class="feather-message-square font-xl text-current"></i></router-link></li>
                    <li>
                        <a href="javascript:void(0)" @click="toggleSidebar()" class="btn-toggle-sidebar"><img :src="user.photo" alt="..." class="w40 rounded-circle mt--1 btn-toggle-sidebar"></a>
                    </li>
                    <li></li>
                </ul>
            </div>
            <div class="middle-sidebar-bottom">
                <div class="middle-sidebar-left" v-if="exist">
                    <router-view/>
                </div>
                <div class="middle-sidebar-left" v-else>
                    <div class="vertical-wrapper pt-lg--7 pt-3">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-md-8 text-center default-page">
                                    <div class="card border-0 text-center d-block">
                                        <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                                        <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                                        <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                                        <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <right-sidebar :personal-event="personalEvent" :is-sidebar-active="isSidebarActive" @close-sidebar="isSidebarActive = false"/>
            </div>            
        </div>
        <!-- main content -->
        <app-footer @button-sidebar-clicked="toggleSidebar" :user="user"/>

        <div class="app-header-search">
            <form class="search-form">
                <div class="form-group searchbox mb-0 border-0 p-1">
                    <input type="text" class="form-control border-0" placeholder="Search...">
                    <i class="fas fa-search">
                        
                    </i>
                    <a href="#" class="ml-1 mt-1 d-inline-block close searchbox-close">
                        <i class="ti-close font-xs"></i>
                    </a>
                </div>
            </form>
        </div>

    </div> 
        
</template>

<script>
import axios from 'axios'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import RightSidebar from '../helpers/RightSidebar.vue'
import AppFooter from '../helpers/AppFooter.vue'
import Notification from '../helpers/Notification.vue'

export default ({
    name: 'CourseAdminLayout',
    components: {
        RightSidebar,
        AppFooter,
        Notification
    },
    data(){
        return {
            media : process.env.VUE_APP_URL_CLOUD,
            user: {
                photo: 'https://elingway-storage.s3.ap-southeast-1.amazonaws.com/default/profile.png'
            },
            course: {},
            exist: true,
            paramsId: this.$route.params.idCourse,
            isSidebarActive: false,
            isNavbarActive: false,
            isMenuActive: false,
            fullpath: this.$route.path,
            options: {
                rewind : true,
                perPage : 3,
                autoplay : true,
                arrows : false,
                pagination: false,
                gap: '1rem'
            },
            personalEvent: 0,
        }
    },
    created() {
        this.getUser()
        this.getCourse()
        
    },
    methods: {
        async getUser(){
            this.user = JSON.parse(localStorage.getItem('user'))
        },
        async getCourse(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/course?slug=` + this.paramsId, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.length != 0) {
                    this.course = res.data
                }
            }).catch(err => {
                if (err.response.status == 404) {
                    this.exist = false
                }
            })
        },
        onLogout() {
            axios.get(`${process.env.VUE_APP_URL_API}/logout`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(() => {
                localStorage.removeItem('token')
                this.$router.push({name: 'Login'})
            }).catch((err) => console.error(err))
        },
        toggleSidebar(){
            this.isSidebarActive = !this.isSidebarActive
        },
        toggleNavbar(){
            this.isNavbarActive = !this.isNavbarActive
        },
        toggleMenu(){
            this.isMenuActive = !this.isMenuActive
        },
        isActiveMenu(path){
            var fullPath = this.fullpath
            if(fullPath.indexOf(path) !== -1){
                return true
            } else {
                return false
            }
        }
    }
})
</script>
